import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import ErrorBoundary from '../ErrorBoundary';
import ReactMarkdown from 'react-markdown';
import { ROUTE } from '../../utils';

const RenderContent = ({ data, truncIt }) => {
  const { pathname } = useRouter();
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!data?.content) {
    return null;
  }

  let content = data.content;

  const ContentWrapper = ({ children }) => (
    <div
      className={`${
        pathname?.startsWith(ROUTE?.POST_DETAILS) ? 'mt-[12px]' : 'mt-[10px]'
      } text-left heading-large-R`}
    >
      <div
        className={`break-words ${
          pathname?.startsWith(ROUTE?.POST_DETAILS)
            ? 'font-inter text-[15px] text-Neutral900 dark:text-Neutral900Dark'
            : 'font-inter text-sm  text-Neutral800 dark:text-Neutral800Dark'
        } text-Neutral800 dark:text-Neutral800Dark ${
          truncIt ? 'truncate-overflow' : ''
        }`}
      >
        {children}
      </div>
    </div>
  );

  const MarkdownContent = () => (
    <ReactMarkdown
      components={{
        p: ({ children }) => <p className="mb-4">{children}</p>,
        ul: ({ children }) => (
          <ul className="list-disc mb-4 ml-4">{children}</ul>
        ),
        ol: ({ children }) => (
          <ol className="list-decimal mb-4 ml-4">{children}</ol>
        ),
        li: ({ children }) => <li className="mb-1">{children}</li>,
        a: ({ href, children }) => (
          <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:underline"
          >
            {children}
          </a>
        ),
        strong: ({ children }) => (
          <strong className="font-bold">{children}</strong>
        ),
        b: ({ children }) => <strong className="font-bold">{children}</strong>,
        em: ({ children }) => <em className="italic">{children}</em>
        // // Add more custom components as needed
      }}
    >
      {content}
    </ReactMarkdown>
  );

  return (
    <ErrorBoundary>
      <ContentWrapper>
        {isClient ? (
          <MarkdownContent />
        ) : (
          <div
            dangerouslySetInnerHTML={{ __html: content.replace(/\n/g, '<br>') }}
          />
        )}
      </ContentWrapper>
    </ErrorBoundary>
  );
};

export default RenderContent;
