import { useRouter } from 'next/router';
import React from 'react';
import { AppConstant } from '../../constants/AppConstant';
import { ROUTE } from '../../utils';

const RenderTrending = ({ data }) => {
  const router = useRouter();

  if (
    data?.flair !== null &&
    router?.pathname.startsWith(ROUTE?.POST_DETAILS) &&
    router?.pathname === ROUTE?.YOU
  ) {
    return (
      <div>
        <p className="text-Neutral800 dark:text-Neutral800Dark heading-small-R max-w-[180px] sm:max-w-xs justify-center block overflow-hidden text-ellipsis whitespace-nowrap text-left">
          {AppConstant?.TRENDING_COMPANY}
        </p>
        <div className=" h-[1px] bg-DividerColor dark:bg-Neutral300Dark -mx-[12px] mt-[4px] mb-3" />
      </div>
    );
  }
};

export default RenderTrending;
