const firstNames = [
  'Karilyn',
  'Kalan',
  'Coy',
  'Aaron',
  'Anise',
  'Matilda',
  'Dezi',
  'Blair',
  'Isaiah',
  'Jordon',
  'Kendall',
  'Kendrick',
  'Lucinda',
  'Riley',
  'Anneliese',
  'Jack',
  'Sutton',
  'Jackson',
  'Anthony',
  'Cameron',
  'Avery',
  'Xavier'
];

const lastNames = [
  'Dean',
  'Olive',
  'Vernon',
  'Taye',
  'Denver',
  'Gabriel',
  'Nadeen',
  'Everett',
  'Hyrum',
  'Lee',
  'Carmden',
  'Erin',
  'Hope',
  'Tyson',
  'Aryn',
  'Ellice',
  'Tristan',
  'Zion',
  'Xavier',
  'Francis'
];

function isFirstCharNumber(firstChar) {
  const parsed = parseInt(firstChar, 10);
  return !isNaN(parsed);
}

function getAsciiValue(char) {
  return char.charCodeAt(0);
}

export function generateRandomName(uuid) {
  let firstChar = uuid.charAt(uuid.length - 1);
  let lastChar = uuid.charAt(uuid.length - 2);

  if (!isFirstCharNumber(firstChar)) {
    firstChar = Math.trunc(getAsciiValue(firstChar) / 10);
  }

  if (!isFirstCharNumber(lastChar)) {
    lastChar = Math.trunc(getAsciiValue(lastChar) / 10);
  }

  const firstName = firstNames[firstChar];
  const lastName = lastNames[lastChar];
  return `${firstName} ${lastName}`;
}
