import Image from 'next/image';
import React from 'react';
import image from '../../assets/Icons';
import icons from '../../assets/leftSide';
import { AppConstant } from '../../constants/AppConstant';
import { colors } from '../../themes';
import Dot from '../Dot';
import Linkify from 'linkify-react';
// import 'linkify-plugin-hashtag';
import 'linkify-plugin-mention';
import { RenderContent } from '../postComp';

const TopComments = ({ topComment }) => {
  const AutoLink = ({ text }) => {
    const delimiter =
      /((?:https?:\/\/)?(?:(?:[a-z0-9]?(?:[a-z0-9\-]{1,61}[a-z0-9])?\.[^\.|\s])+[a-z\.]*[a-z]+|(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3})(?::\d{1,5})*[a-z0-9.,_\/~#&=;%+?\-\\(\\)]*)/gi;

    return text
      ?.split('\n')
      .splice(0, 2)
      .map(text => {
        return (
          <div className="text-Neutral800 heading-medium-R break-all text-left flex flex-wrap">
            {text?.split(' ').map((word, i) => {
              if (word.charAt(0) === '@') {
                return (
                  <div
                    key={i}
                    target="_blank"
                    style={{
                      color: colors.Action,
                      height: 'auto',
                      justifyContent: 'flex-start'
                    }}
                    onClick={e => e.stopPropagation()}
                  >
                    {word}&nbsp;
                  </div>
                );
              } else if (delimiter.test(word)) {
                return (
                  <a
                    key={i}
                    href={word.startsWith('http') ? word : `http://${word}`}
                    target="_blank"
                    style={{
                      color: colors.Action,
                      height: 'auto',
                      justifyContent: 'flex-start'
                    }}
                    rel="noreferrer"
                    onClick={e => e.stopPropagation()}
                  >
                    {word}&nbsp;
                  </a>
                );
              }

              return (
                <div className="flex flex-col" key={i}>
                  {word}&nbsp;
                </div>
              );
            })}
          </div>
        );
      });
  };

  const RenderHeaderProfile = ({ data }) => {
    return (
      <Image
        priority
        className="w-[22px] h-[22px] rounded-full"
        src={
          data?.user?.profile_image !== undefined
            ? data?.user?.profile_image
            : image?.communitylogo
        }
        alt="img"
        width={22}
        height={22}
      />
    );
  };

  const RenderHeader = ({ data }) => {
    return (
      <div className="w-full max-w-[250px] lg:max-w-md">
        <div className="flex items-center">
          <h3 className="text-Neutral900 dark:text-Neutral900Dark title-small-B max-w-[180px] sm:max-w-xs justify-center block overflow-hidden text-ellipsis whitespace-nowrap">
            {AppConstant?.BY} {data?.user?.username}
          </h3>
          {data?.user?.is_company_email_verified && (
            <icons.verified w={14} h={14} className="ml-[4px]" />
          )}
          <Dot className={'bg-Neutral900 dark:bg-Neutral900Dark mx-[4px]'} />
          <h3 className="text-Neutral900 dark:text-Neutral900Dark title-small-B max-w-[180px] sm:max-w-xs justify-center block overflow-hidden text-ellipsis whitespace-nowrap">
            {data?.user?.company}
          </h3>
        </div>
      </div>
    );
  };

  /* ---------------content component--------------- */
  // Remove or comment out the existing RenderContent function
  // const RenderContent = ({ data }) => { ... }

  /* --------------- --------------- */
  return (
    <div className="pb-[1px] pl-2">
      <div className="flex items-center mb-[12px]">
        <h2 className="text-Neutral900 dark:text-Neutral900Dark title-small-B mr-[5px]">
          {AppConstant?.TOP_COMMENTS}
        </h2>
        <icons.stars />
      </div>
      {topComment?.map(comment => {
        return (
          <div className="my-[24px]" key={comment.id}>
            <div className="flex w-full items-center relative">
              <RenderHeader data={comment} />
            </div>
            <div className="sm:w-[500px]">
              <RenderContent data={comment} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TopComments;
