import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { getAccessToken } from './../services/ApiServices';
import generateRefreshToken from '../store/GenerateRefreshToken';
import { store } from '../store/store';
import { setIsAuthenticated } from '../store/login/Reducer';

const checkToken = async () => {
  // const accessToken = await getAccessToken();
  // const currentTime = moment().unix();
  // const decoded = await jwtDecode(accessToken?.replace(/"/g, ''));
  // if (currentTime + 100 > decoded.exp) {
  //   return new Promise((resolve, reject) => {
  //     generateRefreshToken()
  //       .then(newRefereshToken => {
  //         resolve(newRefereshToken.replace(/"/g, ''));
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // } else {
  //   return accessToken.replace(/"/g, '');
  // }
};

export default checkToken;
