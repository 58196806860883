import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPostData } from '../../store/home/Reducer';
import { postVotePoll } from '../../store/home/Action';
import { AppConstant } from '../../constants/AppConstant';
import { likeCounter, ROUTE } from '../../utils';
import { useRouter } from 'next/router';

const RenderPollOptions = ({ data, setItem }) => {
  const router = useRouter();

  const dispatch = useDispatch();
  const { postFeed } = useSelector(state => state);

  const _onVotePollClick = val => {
    if (setItem) {
      setItem({
        vote_by_user: true,
        poll_options: data?.poll_options?.map(p =>
          val?.pollData?.id === p?.id
            ? { ...p, votes_count: p?.votes_count + 1 }
            : p
        )
      });
    }

    let postArr = postFeed?.post?.map(d => {
      if (val?.postID === d?.id) {
        const data = d?.poll_options?.map(p =>
          val?.pollData?.id === p?.id
            ? { ...p, votes_count: p?.votes_count + 1 }
            : p
        );
        return { ...d, vote_by_user: true, poll_options: data };
      }
      return d;
    });

    let newData = {
      post_id: val?.postID,
      poll_option_id: val?.pollData?.id
    };
    postVotePoll(dispatch, newData)
      .then(() => {
        dispatch(setPostData(postArr));
      })
      .catch(() => {});
  };

  var arr = data?.poll_options;
  var sumVotes = 0;
  arr?.forEach(x => {
    sumVotes += x?.votes_count;
  });

  const checkPollExpired = () => {
    const diff = moment().diff(new Date(data?.end_poll_at));
    return diff >= 0;
  };

  const getPollExpireTime = () => {
    return moment(data?.end_poll_at).startOf('day').fromNow();
  };

  // eslint-disable-next-line complexity
  const RenderLikeExpDate = () => {
    return (
      <div className="my-[10px] mx-[16px] flex items-center">
        <p
          className={`${
            router?.pathname.startsWith(ROUTE?.POST_DETAILS)
              ? 'title-medium-B'
              : 'title-smallest-MB'
          } text-Neutral800 dark:text-Neutral800Dark`}
        >
          {likeCounter(sumVotes)}{' '}
          {sumVotes > 1 ? AppConstant?.VOTES : AppConstant?.VOTE}
        </p>

        {!checkPollExpired() && !data?.vote_by_user && (
          <>
            <div className="w-[4px] h-[4px] bg-Neutral800 mx-[10px] rounded-full" />
            <p
              className={`${
                router?.pathname.startsWith(ROUTE?.POST_DETAILS)
                  ? 'title-medium-B'
                  : 'title-smallest-MB'
              } text-Neutral800 dark:text-Neutral800Dark`}
            >
              {`${AppConstant.EXPIRES_IN} ${getPollExpireTime()}`}
            </p>
          </>
        )}
      </div>
    );
  };

  // eslint-disable-next-line complexity
  const RenderPollTitlePer = ({ title, perc }) => {
    return (
      <div className="absolute w-full flex justify-between items-center">
        {perc !== 0 ? (
          <>
            {!checkPollExpired() && !data?.vote_by_user ? (
              <div className="w-[12.37px] h-[12px] border-[2px] border-Action rounded-full ml-[12px]" />
            ) : (
              <p className="text-Neutral900 dark:text-Neutral900Dark title-medium-B pl-[12px] w-[55px] text-left whitespace-nowrap">
                {perc?.toFixed(0)}%
              </p>
            )}
          </>
        ) : !checkPollExpired() && !data?.vote_by_user ? (
          <div className="w-[12.37px] h-[12px] border-[2px] border-Action rounded-full ml-[12px]" />
        ) : (
          <p className="text-Neutral900 dark:text-Neutral900Dark title-medium-B pl-[12px] w-[55px] text-left whitespace-nowrap">
            {perc?.toFixed(0)}%
          </p>
        )}
        <p className="text-Neutral900 dark:text-Neutral900Dark heading-medium-M px-[15px] w-full break-all overflow-hidden text-ellipsis whitespace-nowrap">
          {title}
        </p>
      </div>
    );
  };

  if (data?.poll_options?.length !== 0) {
    return (
      <div
        className={`${
          router?.pathname.startsWith(ROUTE?.POST_DETAILS)
            ? 'mt-[12px]'
            : 'mt-[10px]'
        } py-[2px] rounded-[15px] border-[0.33px] border-DividerColor dark:border-Neutral400Dark`}
      >
        <div>
          {data?.poll_options?.map((d, i) => {
            let perc = (d?.votes_count / sumVotes) * 100;
            if (isNaN(perc)) {
              perc = 0;
            }

            return (
              <div key={i} className="m-[10px] h-[36px]">
                <button
                  className="bg-Neutral200 dark:bg-Neutral200Dark w-full flex rounded-[8px] md:mr-5 mr-1 items-center relative h-[36px]
                  border-[0.33px] border-DividerColor dark:border-Neutral400Dark"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!checkPollExpired() && !data?.vote_by_user) {
                      _onVotePollClick({ postID: data?.id, pollData: d });
                    }
                  }}
                >
                  <div
                    className=" bg-Progress dark:bg-ProgressDark relative rounded-[8px] h-[36px]"
                    style={{
                      width: `${
                        !checkPollExpired() && !data?.vote_by_user
                          ? 0
                          : Math.round(perc, 2)
                      }%`,
                      overflow: 'hidden',
                      display: 'inline-block',
                      transition: 'width 500ms',
                      whiteSpace: 'nowrap'
                    }}
                  />
                  <RenderPollTitlePer title={d?.title} perc={perc} />
                </button>
              </div>
            );
          })}
        </div>

        <div className="h-[1px] bg-DividerColor dark:bg-Neutral300Dark" />

        <RenderLikeExpDate />
      </div>
    );
  }
};

export default RenderPollOptions;
