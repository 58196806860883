import React, { useContext, useState } from 'react';
import icons from '../../assets/leftSide';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import { AppContext } from '../../context/AppProvider';
import { useDispatch, useSelector } from 'react-redux';
import { setCommentData, setPostData } from '../../store/home/Reducer';
import {
  banAPI,
  blockUserAPI,
  boostAPI,
  deleteCommentAPI,
  deletePostAPI,
  deleteWarnCommentAPI,
  deleteWarnPostAPI,
  reportCommentAPI,
  reportPostAPI,
  reportUserAPI
} from '../../store/home/Action';
import { Dialog } from '@mui/material';
import {
  AppConstant,
  ASYNC_KEY,
  TEXT_MENU,
  TOAST_MESSAGE,
  TYPE
} from '../../constants/AppConstant';
import { toast } from 'react-toastify';
import { getFromAsync, showError } from '../../utils';
import * as track from '../../utils/MixpanelTrackEvent';
import { setTabData } from '../../store/user/Reducer';
import { setSearchPostList } from '../../store/search/Reducer';

const PostMenu = ({
  post,
  data,
  getFeedListUpdate,
  type,
  commentid,
  setItem
}) => {
  const context = useContext(AppContext);
  const IsLight = context?.theme === context?.themes[0];
  const dispatch = useDispatch();
  const { postFeed, user, search } = useSelector(state => state);

  const [confModal, setConfModal] = useState(false);
  const [optionData, setOptionData] = useState('');
  let opt = Object?.keys(data?.options ? data?.options : []).map(
    item => {
      return { item: item, value: data?.options[item] };
    }
  );
  let filterMenu = opt?.filter(d => {
    if (d?.value === true) {
      return d;
    }
  });
  // ---------------------------------------

  const openModalObj = {
    report_comment: () => {
      setOptionData({
        heading: AppConstant?.COMMENT_REPORT_TEXT,
        btnText: AppConstant?.REPORT_COMMENT,
        pid: data?.id,
        uid: data?.user?.id
      });
    },
    ban: () => {
      setOptionData({
        heading: AppConstant?.BAN_MSG,
        btnText: AppConstant?.BAN,
        pid: data?.id,
        uid: data?.user?.id
      });
    },
    block_user: () => {
      setOptionData({
        heading: AppConstant?.BLOCK_USER_MSG,
        btnText: AppConstant?.BLOCK_USER,
        pid: data?.id,
        uid: data?.user?.id
      });
    },
    boost: () => {
      setOptionData({
        heading: AppConstant?.BOOST_POST_MSG,
        btnText: AppConstant?.BOOST,
        pid: data?.id,
        uid: data?.user?.id
      });
    },
    delete: () => {
      setOptionData({
        heading:
          type === TYPE?.C_REPLY || type === TYPE?.REPLY
            ? AppConstant?.DELETE_COMMENT
            : AppConstant?.DELETE_MSG,
        btnText: AppConstant?.DELETE,
        pid: data?.id,
        uid: data?.user?.id
      });
    },
    delete_and_warn: () => {
      setOptionData({
        heading:
          type === TYPE?.C_REPLY || type === TYPE?.REPLY
            ? AppConstant?.DELETE_WARN_COMMENT
            : AppConstant?.DELETE_WARN_POST,
        btnText: AppConstant?.DELETE_AND_WARN,
        pid: data?.id,
        uid: data?.user?.id
      });
    },
    report_post: () => {
      setOptionData({
        heading: AppConstant?.REPORT_POST_TEXT,
        btnText: AppConstant?.REPORT_POST,
        pid: data?.id,
        uid: data?.user?.id
      });
    },
    report_user: () => {
      setOptionData({
        heading: AppConstant?.REPORT_USER_TEXT,
        btnText: AppConstant?.REPORT_USER,
        pid: data?.id,
        uid: data?.user?.id
      });
    }
  };

  const menuModal = val => {
    setConfModal(true);
    openModalObj[val]();
  };

  // ---------------------------------------

  const _reportComment = ids => {
    let datas = {
      post_comment_id: ids?.postID,
      reason: ''
    };

    reportCommentAPI(dispatch, datas)
      .then(res => {
        const user = getFromAsync(ASYNC_KEY.USER);
        track.trackCommentReportTap(
          ids?.postID,
          post[0]?.id,
          post[0]?.post_type,
          post[0]?.community?.display_name,
          post[0]?.user?.username,
          post[0]?.created_at,
          data?.likes_count,
          post[0]?.comments_count,
          user?.username
        );
        closeModal();
        toast.success(TOAST_MESSAGE?.COMMENT_REPORT_SUCCESS);
        getFeedListUpdate(res?.data?.status);
      })
      .catch(err => {
        showError(err);
      });
  };

  const _ban = ids => {
    let data = {
      user_id: ids?.userID
    };

    banAPI(dispatch, data)
      .then(res => {
        closeModal();
        toast.success(TOAST_MESSAGE?.POST_BAN_SUCCESS);
        getFeedListUpdate(res?.data?.status);
      })
      .catch(err => {
        showError(err);
      });
  };

  const _blockUser = ids => {
    let data = {
      user_id: ids?.userID,
      reason: ''
    };

    blockUserAPI(dispatch, data)
      .then(res => {
        closeModal();
        toast.success(TOAST_MESSAGE?.BLOCK_REPORT_SUCCESS);
        getFeedListUpdate(res?.data?.status);
      })
      .catch(err => {
        showError(err);
      });
  };

  const _boost = ids => {
    let data = {
      post_id: ids?.postID
    };

    boostAPI(dispatch, data)
      .then(res => {
        closeModal();
        toast.success(TOAST_MESSAGE?.POST_BOOST_SUCCESS);
        getFeedListUpdate(res?.data?.status);
      })
      .catch(err => {
        showError(err);
      });
  };

  const _delete = ids => {
    let arr = [...postFeed?.post];
    const indexOfObject = arr.findIndex(object => {
      return object.id === ids?.postID;
    });
    arr.splice(indexOfObject, 1);
    const tabArr = user.tabData.filter(e => {
      e.id !== ids?.postID;
    });
    const searchPostArr = search.searchPost.filter(e => {
      e.id !== ids?.postID;
    });

    let data = {
      post_id: ids?.postID
    };
    deletePostAPI(dispatch, data)
      .then(res => {
        setConfModal(false);
        toast.success(TOAST_MESSAGE?.POST_DELETE_SUCCESS);
        getFeedListUpdate && getFeedListUpdate(res?.data?.status);
        dispatch(setPostData(arr));
        dispatch(setTabData(tabArr));
        dispatch(setSearchPostList(searchPostArr));
      })
      .catch(err => {
        showError(err);
      });
  };

  const _deleteWarn = ids => {
    let arr = [...postFeed?.post];
    const indexOfObject = arr.findIndex(object => {
      return object.id === ids?.postID;
    });
    arr.splice(indexOfObject, 1);

    let data = {
      post_id: ids?.postID
    };
    deleteWarnPostAPI(dispatch, data)
      .then(res => {
        closeModal();
        toast.success(TOAST_MESSAGE?.POST_DELETE_WARN_SUCCESS);
        getFeedListUpdate(res?.data?.status);
        dispatch(setPostData(arr));
      })
      .catch(err => {
        showError(err);
      });
  };

  const _deleteComment = ids => {
    let arr = [...postFeed?.comments];
    let indexOfObject;
    if (type === TYPE?.C_REPLY) {
      indexOfObject = arr.filter(object => object.id !== ids?.postID);
    } else {
      indexOfObject = arr.map(object => {
        if (object.id === commentid) {
          const newData = object?.replies.filter(obj => obj.id !== ids?.postID);
          return {
            ...object,
            replies: newData,
            replies_count: object?.replies_count - 1
          };
        } else {
          return object;
        }
      });
    }
    if (setItem) {
      setItem({
        comments_count: post[0]?.comments_count - 1
      });
    }
    let postArr = postFeed?.post?.map(d => {
      if (post[0]?.id === d?.id) {
        return {
          ...d,
          comments_count: d?.comments_count - 1
        };
      }
      return d;
    });

    let data = {
      post_comment_id: ids?.postID
    };

    deleteCommentAPI(dispatch, data)
      .then(() => {
        const user = getFromAsync(ASYNC_KEY.USER);
        track.trackCommentDeleteTap(
          ids?.postID,
          post[0]?.id,
          post[0]?.post_type,
          post[0]?.community?.display_name,
          post[0]?.user?.username,
          post[0]?.created_at,
          data?.likes_count,
          post[0]?.comments_count,
          user?.username
        );
        toast.success(TOAST_MESSAGE?.COMMENT_DELETE_SUCCESS);
        dispatch(setPostData(postArr));
        dispatch(setCommentData(indexOfObject));
      })
      .catch(err => {
        if (setItem) {
          setItem({
            comments_count: post[0]?.comments_count
          });
        }
        showError(err);
      })
      .finally(() => setConfModal(false));
  };

  const _deleteWarnComment = ids => {
    let arr = [...postFeed?.comments];
    let indexOfObject;
    if (type === TYPE?.C_REPLY) {
      indexOfObject = arr.filter(object => object.id !== ids?.postID);
    } else {
      indexOfObject = arr.map(object => {
        if (object.id === commentid) {
          const newData = object?.replies.filter(obj => obj.id !== ids?.postID);
          return { ...object, replies: newData };
        } else {
          return object;
        }
      });
    }

    let postArr = postFeed?.post?.map(d => {
      if (post[0]?.id === d?.id) {
        return {
          ...d,
          comments_count: d?.comments_count - 1
        };
      }
      return d;
    });
    let data = {
      post_comment_id: ids?.postID
    };
    deleteWarnCommentAPI(dispatch, data)
      .then(() => {
        const user = getFromAsync(ASYNC_KEY.USER);
        track.trackCommentDeleteWarnTap(
          ids?.postID,
          post[0]?.id,
          post[0]?.post_type,
          post[0]?.community?.display_name,
          post[0]?.user?.username,
          post[0]?.created_at,
          data?.likes_count,
          post[0]?.comments_count,
          user?.username
        );
        setConfModal(false);
        toast.success(TOAST_MESSAGE?.COMMENT_DELETE_WARN_SUCCESS);
        dispatch(setPostData(postArr));
        dispatch(setCommentData(indexOfObject));
      })
      .catch(err => {
        showError(err);
      });
  };

  const _reportPost = ids => {
    let data = {
      post_id: ids?.postID,
      reason: ''
    };
    reportPostAPI(dispatch, data)
      .then(res => {
        closeModal();
        toast.success(TOAST_MESSAGE?.POST_REPORT_SUCCESS);
        getFeedListUpdate(res?.data?.status);
      })
      .catch(err => {
        showError(err);
      });
  };

  const _reportUser = ids => {
    let data = {
      user_id: ids?.userID,
      reason: ''
    };
    reportUserAPI(dispatch, data)
      .then(res => {
        closeModal();
        toast.success(TOAST_MESSAGE?.USER_REPORT_SUCCESS);
        getFeedListUpdate(res?.data?.status);
      })
      .catch(err => {
        showError(err);
      });
  };

  function closeModal() {
    setConfModal(false);
    setTimeout(() => {
      setOptionData('');
    }, 200);
  }

  const clickObj = {
    [AppConstant?.REPORT_COMMENT]: _reportComment,
    [AppConstant?.BAN]: _ban,
    [AppConstant?.BLOCK_USER]: _blockUser,
    [AppConstant?.BOOST]: _boost,
    [AppConstant?.DELETE]:
      type === TYPE?.C_REPLY || type === TYPE?.REPLY ? _deleteComment : _delete,
    [AppConstant?.DELETE_AND_WARN]: () => {
      type === TYPE?.C_REPLY || type === TYPE?.REPLY
        ? _deleteWarnComment
        : _deleteWarn;
    },
    [AppConstant?.REPORT_POST]: _reportPost,
    [AppConstant?.REPORT_USER]: _reportUser
  };

  const menuClick = val => {
    clickObj[val?.text](val);
  };

  return (
    <div className="flex justify-center items-center">
      <Menu
        transition
        menuStyle={{ borderRadius: '7px' }}
        menuButton={
          <MenuButton className="relative pl-1 pr-1">
            <icons.moreVertical />
          </MenuButton>
        }
      >
        <div className="bg-white dark:bg-Neutral400Dark rounded-[4px]">
          <div className="w-[200px] menuStyle justify-center py-[10px]">
            {filterMenu?.map((d, i) => {
              return (
                <MenuItem key={i} className="!p-0">
                  <div
                    className="flex w-[200px] h-[40px] my-[5px] items-center"
                    onClick={() => {
                      menuModal(d?.item);
                    }}
                  >
                    <div className="ml-[15px] mr-[10px]">
                      {TEXT_MENU[d?.item]?.Icon(!IsLight)}
                    </div>
                    <p
                      className="text-Action title-medium-B"
                      style={{
                        color: TEXT_MENU[d?.item].color(!IsLight)
                      }}
                    >
                      {TEXT_MENU[d?.item]?.Title}
                    </p>
                  </div>
                </MenuItem>
              );
            })}
          </div>
        </div>
      </Menu>
      <Dialog
        fullWidth
        open={confModal}
        className="w-full"
        maxWidth={'xs'}
        onClose={() => {
          closeModal();
        }}
      >
        <div className="py-5 bg-White dark:bg-DarkBg">
          <p className="title-extra-large-B text-Neutral900 dark:text-Neutral900Dark text-center px-5">
            {optionData?.heading}
          </p>
          <div className="flex justify-around">
            <button
              className="h-[35px] justify-center rounded-[100px] bg-Action flex px-[15px] mt-[16px] items-center"
              onClick={() => closeModal()}
            >
              <p className="title-large-B text-White">{AppConstant?.CANCEL}</p>
            </button>
            <button
              className="h-[35px] justify-center rounded-[100px] bg-Warning flex px-[15px] mt-[16px] items-center"
              onClick={() =>
                menuClick({
                  postID: optionData?.pid,
                  userID: optionData?.uid,
                  text: optionData?.btnText
                })
              }
            >
              <p className="title-large-B text-White">{optionData?.btnText}</p>
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default PostMenu;
