import React, { useContext, useEffect, useState } from 'react';
import { AppConstant } from '../constants/AppConstant';
import { AppContext } from '../context/AppProvider';
import { colors } from '../themes';
import { isMobileDevice } from '../utils';

const btnColor = {
  backgroundImage: `linear-gradient(${86.48}deg, ${colors?.linearGr}, ${
    colors?.linearGrDark
  })`
};

const Footer = () => {
  const context = useContext(AppContext);
  const [IsLight, setIsLight] = useState(false);

  useEffect(() => {
    setIsLight(context?.theme === context?.themes[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.theme]);

  const _isLoginClick = () => {
    if (isMobileDevice()) {
      context.downloadAppModalShow()
      return
    }

    context.setLoginModal(true);
  };

  return (
    <div
      className="rounded-[12px]
      border-[0.33px]
      lg:w-[638px] xl:w-[552px] w-full bg-White dark:bg-DarkBg cursor-pointer
     border-DividerColor dark:border-Neutral400Dark px-[12px] mb-6
    "
    >
      <p className="title-large-MB text-Neutral900 dark:text-Neutral900Dark mt-[15px]">
        {AppConstant?.FOOTER_TITLE}
      </p>
      <p className="title-smallest-MB text-Neutral800 mt-[10px]">
        {AppConstant?.FOOTER_INFO}
      </p>
      <div className="w-full text-center">
        <button
          className=" h-[37px] mt-[22px] px-[14px] rounded-full items-center mb-[15px]"
          style={btnColor}
          onClick={() => _isLoginClick()}
        >
          <p className="title-medium-B text-White">{AppConstant?.SIGN_UP}</p>
        </button>
      </div>
    </div>
  );
};

export default Footer;
