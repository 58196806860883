/* eslint-disable complexity */
import React, { useContext, useEffect, useState } from 'react';
import icons from '../../assets/leftSide';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import { getFromAsync, hasValue, ROUTE, showError } from '../../utils';
import { AppContext } from '../../context/AppProvider';
import { colors } from '../../themes';
import buildLink from '../../services/firebaseDynamicLink';
import {
  AppConstant,
  ASYNC_KEY,
  TOAST_MESSAGE,
  TYPE
} from '../../constants/AppConstant';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import Image from 'next/image';
import image from '../../assets/Icons';
import Dot from '../Dot';
import { getUserAPI } from '../../store/user/Action';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';

const ViewUser = ({ data, load }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const context = useContext(AppContext);
  const IsLight = context?.theme === context.themes[0];
  const [user, setUser] = useState('');
  const [userLoading, setUserLoading] = useState(false);

  useEffect(() => {
    if (context.token) {
      userAPIFun(data?.user);
    } else {
      context.setLoginModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const userAPIFun = id => {
    setUserLoading(true);
    let obj;
    if (data?.user?.username) {
      obj = `?username=${id?.username}`;
    } else {
      obj = `?user_id=${id?.id}`;
    }
    getUserAPI(dispatch, obj)
      .then(res => {
        setUser(res?.data?.data);
        setUserLoading(false);
      })
      .catch(err => {
        setUserLoading(false);
        showError(err);
      })
      .finally(() => {
        setUserLoading(false);
      });
  };

  return (
    <>
      {userLoading ? (
        <div className="flex justify-center">
          <CircularProgress sx={{ mt: 2, mb: 2 }} />
        </div>
      ) : (
        <div className="flex flex-col items-center py-[20px] mx-[12px]">
          <div className="mb-[15px]">
            <Image
              priority
              className="w-[56px] h-[56px] rounded-full"
              src={
                user?.profile_image !== null
                  ? user?.profile_image
                  : image?.communitylogo
              }
              alt="img"
              width={56}
              height={56}
            />
          </div>
          <div className="flex items-center my-[1.5px] max-w-[253px]">
            <p className="text-Neutral900 dark:text-Neutral900Dark title-large-MB">
              {user?.username}
            </p>
            {user?.is_company_email_verified ? (
              <icons.verified w={16} h={16} className="mx-[4px]" />
            ) : (
              <Dot
                className={
                  'bg-Neutral900 dark:bg-Neutral900Dark mx-[4px] !w-[3px] !h-[3px]'
                }
              />
            )}
            <p className="text-Neutral900 dark:text-Neutral900Dark title-large-MB max-w-[180px] sm:max-w-xs justify-center block overflow-hidden text-ellipsis whitespace-nowrap">
              {user?.company?.display_name}
            </p>
          </div>
          {user?.is_company_email_verified && (
            <div className="flex items-center justify-center px-[15.5px] mt-[12px] h-[32px] bg-Neutral200 dark:bg-Neutral200Dark rounded-full">
              <icons.verified w={16} h={16} />
              <p className="text-Action heading-small-R ml-[5px]">
                {AppConstant?.USER_VERIFIED_EMAIL}
              </p>
            </div>
          )}

          {user?.bio && (
            <div className="text-center mt-[16px] w-full">
              <p className="text-Neutral900 dark:text-Neutral900Dark heading-medium-R">
                {user?.bio}
              </p>
            </div>
          )}
          {/* <div className="w-full h-[1px] bg-DividerColor dark:bg-Neutral300Dark my-4" /> */}
          {/* <div className="flex w-full items-center text-left">
            <icons.plus />
            <p className="ml-[8px] text-Neutral800 heading-small-M">
              {AppConstant?.GET_CHAT_WITH} {user?.username}
            </p>
          </div> */}
        </div>
      )}
    </>
  );
};

export default ViewUser;
