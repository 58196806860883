import React, { useContext } from 'react';
import icons from '../../assets/leftSide';
import { AppContext } from '../../context/AppProvider';
import Image from 'next/image';
import image from '../../assets/Icons';
import { useRouter } from 'next/router';
import { ROUTE } from '../../utils';

const RenderLink = ({ data }) => {
  const router = useRouter();

  const context = useContext(AppContext);
  const IsLight = context?.theme === context?.themes[0];

  const onCLick = e => {
    e.stopPropagation();
    window.open(data?.url, '_blank');
  };

  return (
    <span
      className={`${
        router?.pathname.startsWith(ROUTE?.POST_DETAILS)
          ? 'mt-[12px]'
          : 'mt-[10px]'
      } flex bg-Neutral200 dark:bg-Neutral200Dark pt-[8px] pb-[6px] pl-[8px] pr-[8px]`}
      style={{
        width: 'auto',
        height: 'auto',
        borderRadius: 10,
        justifyContent: 'flex-start'
      }}
      rel="noreferrer"
      onClick={e => {
        e?.stopPropagation();
        onCLick(e);
      }}
    >
      <div className="overflow-hidden w-full">
        <p className="text-Neutral800 dark:text-Neutral800Dark heading-medium-M mt-[6px] text-left truncate">
          {data?.link_description?.length > 140
            ? `${data?.link_description.slice(0, 140)}...`
            : data?.link_description}
        </p>
        <div className="flex mt-[5px] items-center text-left">
          <p
            className="text-Neutral800 dark:text-Neutral800Dark title-small-B"
            style={{ wordWrap: 'break-word' }}
          >
            {data?.url}
          </p>
          <button className="w-[14px] h-[14px] ml-[6.75px]">
            <icons.linkShare />
          </button>
        </div>
      </div>
      <div className="ml-[12px] my-[4px] rounded-[12px]">
        <Image
          priority
          className="min-w-[101px] max-w-[101px] min-h-[101px] max-h-[101px] rounded-[12px] object-cover"
          src={
            data?.image !== null
              ? data?.image
              : IsLight
              ? image?.imagePlaceholder
              : image?.darkImgPlaceholder
          }
          alt="img"
          width={101}
          height={101}
        />
      </div>
    </span>
  );
};

export default RenderLink;
