import icons from '../../assets/leftSide';
import { colors } from '../../themes';
import { ShareMenu } from '.';
import { AppConstant, POST_TYPE } from '../../constants/AppConstant';
import { useRouter } from 'next/router';
import { ROUTE } from '../../utils';
import { useContext } from 'react';
import { AppContext } from '../../context/AppProvider';

// eslint-disable-next-line complexity
const PostButtons = ({ data, like, comment, bookmark, type }) => {
  const context = useContext(AppContext);
  

  const router = useRouter();
  const Colors = {
    like: {
      fill: colors.Primary.DEFAULT,
      stroke: colors?.Primary.DEFAULT
    },
    unlike: {
      fill: '',
      stroke: colors?.Neutral800
    },
    bookmark: {
      fill: colors.Primary.DEFAULT,
      stroke: colors?.Primary.DEFAULT
    },
    unbookmark: {
      fill: '',
      stroke: colors?.Neutral800
    }
  };

  const Bookmark = ({ data }) => {
    return (
      <button
        className="px-[5.83px] flex items-center"
        aria-label='bookmark'
        onClick={e => bookmark(e)}
      >
        <icons.bookmark
          color={data?.bookmarked ? Colors?.bookmark : Colors?.unbookmark}
          className="mr-[11.83px]"
        />
      </button>
    );
  };

  return (
    <div className="h-[32px] flex  relative pb-[16px] px-[8px]">
      <button
        className="px-[5.83px] mr-[18px] flex items-center"
        aria-label='like'
        onClick={e => like(e)}
      >
        <icons.like
          w={20}
          h={20}
          color={data?.liked ? Colors?.like : Colors?.unlike}
          className="mr-2"
        />
        <p
          className={`${data?.liked ? 'text-Primary' : 'text-Neutral800'} ${
            router?.pathname.startsWith(ROUTE?.POST_DETAILS)
              ? 'title-medium-B'
              : 'title-small-B'
          } text-left`}
        >
          {data?.likes_count === 0 ? AppConstant?.LIKE : data?.likes_count}
        </p>
      </button>
      {!data?.post_type?.includes(POST_TYPE?.ENGAGEMENT) && (
        <button
          className="px-[5.83px] mr-[18px] flex items-center"
          aria-label='comment'
          onClick={e => comment(e)}
        >
          <icons.comment className="mr-[10px]" />
          <p
            className={`${
              router?.pathname.startsWith(ROUTE?.POST_DETAILS)
                ? 'title-medium-B'
                : 'title-small-B'
            } text-Neutral800 dark:text-Neutral800Dark text-left`}
          >
            {data?.comments_count === 0
              ? AppConstant?.COMMENT
              : data?.comments_count}
          </p>
        </button>
      )}
      <div
        className="px-[5.83px] mr-[18px] flex items-center"
        onClick={e => {
          e?.stopPropagation();
        }}
      >
        <ShareMenu data={data} type={type} />
      </div>
      {!data?.post_type?.includes(POST_TYPE?.ENGAGEMENT) && (
        <Bookmark data={data} />
      )}
    </div>
  );
};

export default PostButtons;
