/* eslint-disable complexity */

import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppConstant,
  ASYNC_KEY,
  POST_TYPE,
  TYPE
} from '../../constants/AppConstant';
import {
  bookmarkAPI,
  getDisLikeAPI,
  getLikeAPI,
  unBookmarkAPI
} from '../../store/home/Action';
import { setCommentData, setPostData } from '../../store/home/Reducer';
import {
  formatTitle,
  getFromAsync,
  isMobileDevice,
  ROUTE,
  showError
} from '../../utils';
import { PostFeed } from '.';
import PostButtons from './PostButtons';
import * as track from '../../utils/MixpanelTrackEvent';
import Footer from '../Footer';
import { AppContext } from '../../context/AppProvider';
import { setTabData } from '../../store/user/Reducer';
import { setSearchPostList } from '../../store/search/Reducer';
import Lottie from 'lottie-react';
import LOAD from '../../utils/spinner.json';
import checkToken from '../../services/CheckToken';
import { setIntraPostData } from '../../store/intra/Reducer';
import { setDiscoverPostsByPostId } from '../../store/discoverPosts/discoverPostsSlice';

const Posts = props => {
  const router = useRouter();
  const context = useContext(AppContext);

  const dispatch = useDispatch();
  const { postFeed, intraFeed, user, search } = useSelector(state => state);
  const { postIdToRelatedPosts } = useSelector(state => state.discoverPosts);
  const [checkLocal, setCheckLocal] = useState(null);
  const [likeDisable, setLikeDisable] = useState(false);
  const [bookMarkDisable, setBookMarkDisable] = useState(false);

  useEffect(() => {
    localData();
    dispatch(setCommentData([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const localData = async () => {
    let tokenData = await getFromAsync(ASYNC_KEY.ACCESS_TOKEN);
    setCheckLocal(tokenData);
  };

  const _onLikes = val => {
    if (props?.setItem) {
      props?.setItem({
        liked: !val?.check ? true : false,
        likes_count: !val?.check
          ? props?.data[0]?.likes_count + 1
          : props?.data[0]?.likes_count - 1
      });
    }

    let postArr;
    let updatedDiscoverPostsForPostId;

    updatedDiscoverPostsForPostId = Object.keys(
      postIdToRelatedPosts || {}
    ).reduce((acc, postId) => {
      acc[postId] = postIdToRelatedPosts[postId]?.map(d => {
        if (val?.id === d?.id) {
          return {
            ...d,
            liked: !val?.check ? true : false,
            likes_count: !val?.check ? d?.likes_count + 1 : d?.likes_count - 1
          };
        }
        return d;
      });

      return acc;
    }, {});

    if (router.pathname.includes(ROUTE.HOME)) {
      postArr = postFeed?.post?.map(d => {
        if (val?.id === d?.id) {
          return {
            ...d,
            liked: !val?.check ? true : false,
            likes_count: !val?.check ? d?.likes_count + 1 : d?.likes_count - 1
          };
        }
        return d;
      });
    }
    if (router.pathname.includes(ROUTE.INTRA)) {
      postArr = intraFeed?.post?.map(d => {
        if (val?.id === d?.id) {
          return {
            ...d,
            liked: !val?.check ? true : false,
            likes_count: !val?.check ? d?.likes_count + 1 : d?.likes_count - 1
          };
        }
        return d;
      });
    }

    let userTabArr = user?.tabData?.map(d => {
      if (val?.id === d?.id) {
        return {
          ...d,
          liked: !val?.check ? true : false,
          likes_count: !val?.check ? d?.likes_count + 1 : d?.likes_count - 1
        };
      }
      return d;
    });

    let searchPostArr = search?.searchPost?.map(d => {
      if (val?.id === d?.id) {
        return {
          ...d,
          liked: !val?.check ? true : false,
          likes_count: !val?.check ? d?.likes_count + 1 : d?.likes_count - 1
        };
      }
      return d;
    });

    let data = {
      post_id: val?.id
    };
    setLikeDisable(true);
    let API = !val?.check ? getLikeAPI : getDisLikeAPI;
    API(dispatch, data)
      .then(() => {
        setLikeDisable(false);

        if (router.pathname.includes(ROUTE.HOME)) {
          dispatch(setPostData(postArr));
        }
        if (router.pathname.includes(ROUTE.INTRA)) {
          dispatch(setIntraPostData(postArr));
        }
        dispatch(setDiscoverPostsByPostId(updatedDiscoverPostsForPostId));
        dispatch(setTabData(userTabArr));
        dispatch(setSearchPostList(searchPostArr));
      })
      .catch(err => {
        if (props?.setItem) {
          props?.setItem({
            liked: props?.data[0]?.liked,
            likes_count: props?.data[0]?.likes_count
          });
        }
        setLikeDisable(false);
        showError(err);
      });
  };

  const _bookMarkPost = val => {
    if (props?.setItem) {
      props?.setItem({
        bookmarked: !val?.check ? true : false
      });
    }
    let postArr = postFeed?.post?.map(d => {
      if (val?.id === d?.id) {
        return {
          ...d,
          bookmarked: !val?.check ? true : false
        };
      }
      return d;
    });
    let userTabArr = user?.tabData?.map(d => {
      if (val?.id === d?.id) {
        return {
          ...d,
          bookmarked: !val?.check ? true : false
        };
      }
      return d;
    });
    let searchPostArr = search?.searchPost?.map(d => {
      if (val?.id === d?.id) {
        return {
          ...d,
          bookmarked: !val?.check ? true : false
        };
      }
      return d;
    });

    const updatedDiscoverPostsForPostId = Object.keys(
      postIdToRelatedPosts || {}
    ).reduce((acc, postId) => {
      acc[postId] = postIdToRelatedPosts[postId]?.map(d => {
        if (val?.id === d?.id) {
          return {
            ...d,
            bookmarked: !val?.check ? true : false
          };
        }
        return d;
      });

      return acc;
    }, {});

    let data = {
      post_id: val?.id
    };
    let post = props?.data[0];
    if (!val?.check) {
      track.trackPostDetailBookmarked(
        post?.id,
        post?.post_type,
        post?.community?.display_name,
        post?.user?.username,
        post?.created_at,
        post?.likes_count,
        AppConstant?.FEED
      );
      if (router?.pathname === ROUTE?.YOU) {
        track.trackappOnBookmark();
      }
    } else {
      track.trackPostDetailBookmarkRemoved(
        post?.id,
        post?.post_type,
        post?.community?.display_name,
        post?.user?.username,
        post?.created_at,
        post?.likes_count,
        AppConstant?.FEED
      );
    }
    setBookMarkDisable(true);
    let API = !val?.check ? bookmarkAPI : unBookmarkAPI;
    API(dispatch, data)
      .then(res => {
        setBookMarkDisable(false);
        dispatch(setPostData(postArr));
        dispatch(setTabData(userTabArr));
        dispatch(setSearchPostList(searchPostArr));
        dispatch(setDiscoverPostsByPostId(updatedDiscoverPostsForPostId));
      })
      .catch(err => {
        if (props?.setItem) {
          props?.setItem({
            bookmarked: props?.data[0]?.bookmarked
          });
        }
        setBookMarkDisable(false);
        showError(err);
      });
  };

  const onPostClick = (id, title) => {
    router.push(
      props?.overrideNavigation
        ? props?.overrideNavigation(id, title)
        : `${ROUTE?.POST_DETAILS}/${formatTitle(title)}-${id}`
    );
  };

  return (
    <>
      {props?.data?.map((d, i) => {
        if (d?.post_type !== 'engagement:share:intra') {
          return (
            <div
              key={d?.id + i}
              className={`${
                !router?.pathname.startsWith(ROUTE?.POST_DETAILS)
                  ? router?.pathname === ROUTE?.SEARCH
                    ? 'rounded-[12px] border-[0.33px] border-t-[0px] '
                    : 'border-[0.33px] rounded-[12px] mb-[10px]'
                  : 'border-x-[0.33px] mb-[10px]'
              } bg-White dark:bg-DarkBg
             border-DividerColor dark:border-Neutral400Dark
            `}
            >
              {router.pathname.includes(ROUTE.POST_DETAILS) &&
              !props?.isDiscoverPost ? (
                <div>
                  <div className="p-[16px]">
                    <PostFeed
                      isLinkDisabled={props?.isLinkDisabled}
                      setItem={props?.setItem}
                      data={d}
                      getFeedListUpdate={() => props?.getFeedListUpdate()}
                      showMainHeader={props?.showMainHeader}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="cursor-pointer"
                  onClick={() => onPostClick(d?.id, d?.title)}
                >
                  <div
                    onClick={() => {
                      props.type !== undefined &&
                        track.trackSearchResultTap(
                          '1',
                          d?.id,
                          d?.community?.display_name
                        );

                      if (
                        !router?.pathname.startsWith(ROUTE?.POST_DETAILS) &&
                        !d?.post_type.includes(POST_TYPE?.ENGAGEMENT)
                      ) {
                        if (checkLocal) {
                          router.push({
                            pathname: props?.overrideNavigation
                              ? props?.overrideNavigation(d?.id, d?.title)
                              : ROUTE?.POST_DETAILS +
                                '/' +
                                formatTitle(d?.title) +
                                '-' +
                                d?.id,
                            screen: ROUTE.HOME
                          });
                        } else {
                          if (context.freePostCheck) {
                            //  context.setFreePostCheck(e => e - 1);
                            router.push({
                              pathname: props?.overrideNavigation
                                ? props?.overrideNavigation(d?.id, d?.title)
                                : ROUTE?.POST_DETAILS +
                                  '/' +
                                  formatTitle(d?.title) +
                                  '-' +
                                  d?.id,
                              screen: ROUTE.HOME
                            });
                          } else {
                            if (isMobileDevice()) {
                              context.downloadAppModalShow();
                              return;
                            }

                            context.setLoginModal(true);
                          }
                        }
                      }
                    }}
                  >
                    <div
                      className={`${
                        !router?.pathname.startsWith(ROUTE?.POST_DETAILS) ||
                        props.isDiscoverPost
                          ? ''
                          : 'pt-[64px] pb-[6px]'
                      } p-[16px]`}
                    >
                      <PostFeed
                        truncIt={props?.truncIt}
                        setItem={props?.setItem}
                        data={d}
                        overrideNavigatio={props?.overrideNavigation}
                        getFeedListUpdate={() => props?.getFeedListUpdate()}
                        hideBackButton={props?.isDiscoverPost}
                      />
                    </div>
                  </div>
                </div>
              )}
              <PostButtons
                data={d}
                postData={d}
                like={e => {
                  e?.stopPropagation();
                  if (checkLocal) {
                    if (!likeDisable) {
                      _onLikes({ id: d?.id, check: d?.liked });
                    }
                  } else {
                    if (isMobileDevice()) {
                      context.downloadAppModalShow();
                      return;
                    }

                    context.setLoginModal(true);
                  }
                }}
                comment={e => {
                  if (checkLocal) {
                    if (props?.overrideNavigation) {
                      router.push({
                        pathname: props?.overrideNavigation
                          ? props?.overrideNavigation(d?.id)
                          : ROUTE?.POST_DETAILS + '/' + d?.id,
                        screen: ROUTE.HOME
                      });
                      return;
                    }
                    if (
                      router.pathname.includes(ROUTE.HOME) ||
                      router.pathname.includes(ROUTE.INTRA)
                    ) {
                      router.push({
                        pathname: props?.overrideNavigation
                          ? props?.overrideNavigation(d?.id)
                          : ROUTE?.POST_DETAILS + '/' + d?.id,
                        screen: ROUTE.HOME
                      });
                    }
                  } else {
                    e?.stopPropagation();

                    if (isMobileDevice()) {
                      context.downloadAppModalShow();
                      return;
                    }

                    context.setLoginModal(true);
                  }
                }}
                bookmark={e => {
                  e?.stopPropagation();
                  if (checkLocal) {
                    if (!bookMarkDisable) {
                      _bookMarkPost({ id: d?.id, check: d?.bookmarked });
                    }
                  } else {
                    if (isMobileDevice()) {
                      context.downloadAppModalShow();
                      return;
                    }

                    context.setLoginModal(true);
                  }
                }}
                getFeedListUpdate={val => props?.getFeedListUpdate(val)}
                type={TYPE?.BIG}
              />
              {d?.top_comments?.length !== 0 &&
                !d?.post_type?.includes(POST_TYPE?.ENGAGEMENT) &&
                !router?.pathname.startsWith(ROUTE?.HOME) &&
                !checkLocal &&
                !props?.disableComments && (
                  <>
                    {/* <div className="h-[0.5px] bg-DividerColor dark:bg-Neutral300Dark mt-[10px] mb-3" /> */}
                    {/* <TopComments data={d} topComment={d?.top_comments} /> */}
                  </>
                )}
            </div>
          );
        }
      })}
      {router?.pathname !== (ROUTE?.POST_DETAILS || ROUTE?.YOU) &&
        !checkToken && <Footer />}
      {props?.lottie && (
        <div className="flex justify-center mb-[10px]">
          <div className="w-[50px] h-[50px]">
            <Lottie animationData={LOAD} />
          </div>
        </div>
      )}
    </>
  );
};

export default Posts;
