import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { ASYNC_KEY, POST_TYPE } from '../../constants/AppConstant';
import { formatTitle, getFromAsync, ROUTE } from '../../utils';
import {
  RenderTrending,
  RenderHeader,
  PostMenu,
  RenderLink,
  RenderContent,
  RenderPollOptions,
  RenderEngagement,
  RenderSalary
} from '../postComp';
import { Lightbox } from 'react-modal-image';
import Link from 'next/link';
import Image from 'next/image';

/* ---------------image component--------------- */

const RenderImage = ({ data }) => {
  const router = useRouter();
  const [open, setOpen] = useState(false);
  if (data?.image !== null && data?.post_type === POST_TYPE?.IMAGE) {
    return (
      <>
        <div
          className={`${
            router?.pathname.startsWith(ROUTE?.POST_DETAILS)
              ? 'mt-[12px]'
              : 'mt-[10px]'
          }`}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setOpen(true);
          }}
        >
          <Image
            style={{
              objectFit: 'contain'
            }}
            width={600}
            height={700}
            alt="img"
            src={data?.image}
            className="rounded-[15px] border-[0.33px] border-DividerColor dark:border-Neutral400Dark w-full "
          />
        </div>
        {open && (
          <div
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Lightbox
              medium={data?.image}
              large={data?.image}
              onClose={() => {
                setOpen(!open);
              }}
            />
          </div>
        )}
      </>
    );
  }
};

const PostFeed = ({
  data,
  getFeedListUpdate,
  setItem,
  showMainHeader,
  truncIt,
  hideBackButton = false,
  overrideNavigation,
  isLinkDisabled
}) => {
  const router = useRouter();
  

  const [checkToken, setCheckToken] = useState(null);

  useEffect(() => {
    localData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const localData = async () => {
    let tokenData = await getFromAsync(ASYNC_KEY.ACCESS_TOKEN);
    setCheckToken(tokenData);
  };

  /* --------------- --------------- */
  /* ---------------image component--------------- */
  const RenderTitle = ({ data }) => {
    return (
      <div
        className={`${
          router?.pathname.startsWith(ROUTE?.POST_DETAILS) ? '' : 'mt-[10px]'
        } text-left md:w-[618px] xl:w-[532px] w-full`}
      >
        {isLinkDisabled ? (
          <h1
            className={`${
              router?.pathname.startsWith(ROUTE?.POST_DETAILS)
                ? 'title-large-XL'
                : 'title-large-MB'
            } text-Neutral900 dark:text-Neutral900Dark`}
            style={{ wordWrap: 'break-word' }}
          >
            {data?.title}
          </h1>
        ) : (
          <Link
            href={
              overrideNavigation
                ? overrideNavigation(data?.id)
                : `${ROUTE?.POST_DETAILS}/${formatTitle(data?.title)}-${
                    data?.id
                  }`
            }
          >
            <h1
              className={`${
                router?.pathname.startsWith(ROUTE?.POST_DETAILS)
                  ? 'title-large-XL'
                  : 'title-large-MB'
              } text-Neutral900 dark:text-Neutral900Dark`}
              style={{ wordWrap: 'break-word' }}
            >
              {data?.title}
            </h1>
          </Link>
        )}
      </div>
    );
  };
  /* --------------- --------------- */
  return (
    <>
      {!data?.post_type?.includes(POST_TYPE?.ENGAGEMENT) ? (
        <>
          {/* ---------------Trending component--------------- */}
          <RenderTrending data={data} />
          {/* --------------- --------------- */}
          {!showMainHeader && (
            <div className="flex items-center">
              <RenderHeader data={data} hideBackButton={hideBackButton} />
              {checkToken && (
                <div onClick={e => e?.stopPropagation()}>
                  <PostMenu
                    data={data}
                    getFeedListUpdate={() => getFeedListUpdate()}
                  />
                </div>
              )}
            </div>
          )}
          {/* ---------------title and post link component--------------- */}
          <RenderTitle data={data} />
          {/* ---------------content component--------------- */}
          <RenderContent data={data} truncIt={truncIt} />
          {/* --------------- --------------- */}
          {data?.post_type?.includes(POST_TYPE?.LINK) && (
            <RenderLink data={data} />
          )}
          {/* --------------- --------------- */}
          {/* ---------------image component--------------- */}
          <div onClick={e => e?.stopPropagation()}>
            <RenderImage data={data} />
          </div>
          {/* --------------- --------------- */}
          {/* ---------------percentage progress component--------------- */}
          {data?.post_type?.includes(POST_TYPE?.POLL) && (
            <RenderPollOptions data={data} setItem={setItem} />
          )}
          {data?.post_type?.includes(POST_TYPE?.SALARY) && (
            <RenderSalary data={data} setItem={setItem} />
          )}

          {/* --------------- --------------- */}
        </>
      ) : (
        <RenderEngagement data={data} />
      )}
    </>
  );
};

export default PostFeed;
