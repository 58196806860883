import moment from 'moment';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPostData } from '../../store/home/Reducer';
import { postVotePoll } from '../../store/home/Action';
import { AppConstant } from '../../constants/AppConstant';
import { hasValue, likeCounter, ROUTE, salaryDiff } from '../../utils';
import { useRouter } from 'next/router';
import icons from '../../assets/leftSide';
import { colors } from '../../themes';
import Dot from '../Dot';

const RenderSalary = ({ data, setItem }) => {
  const router = useRouter();

  const dispatch = useDispatch();
  const { postFeed } = useSelector(state => state);

  const _onVotePollClick = val => {
    if (setItem) {
      setItem({
        vote_by_user: true,
        poll_options: data?.poll_options?.map(p =>
          val?.pollData?.id === p?.id
            ? { ...p, votes_count: p?.votes_count + 1 }
            : p
        )
      });
    }

    let postArr = postFeed?.post?.map(d => {
      if (val?.postID === d?.id) {
        const data = d?.poll_options?.map(p =>
          val?.pollData?.id === p?.id
            ? { ...p, votes_count: p?.votes_count + 1 }
            : p
        );
        return { ...d, vote_by_user: true, poll_options: data };
      }
      return d;
    });
    dispatch(setPostData(postArr));

    let newData = {
      post_id: val?.postID,
      poll_option_id: val?.pollData?.id
    };
    postVotePoll(dispatch, newData)
      .then(() => {
        dispatch(setPostData(postArr));
      })
      .catch(() => {});
  };

  var arr = data?.poll_options;
  var sumVotes = 0;
  arr?.forEach(x => {
    sumVotes += x?.votes_count;
  });

  const checkPollExpired = () => {
    const diff = moment().diff(new Date(data?.end_poll_at));
    return diff >= 0;
  };

  const getPollExpireTime = () => {
    return moment(data?.end_poll_at).startOf('day').fromNow();
  };

  // eslint-disable-next-line complexity
  const RenderLikeExpDate = () => {
    return (
      <div className="my-[10px] mx-[16px] flex items-center">
        <p
          className={`${
            router?.pathname.startsWith(ROUTE?.POST_DETAILS)
              ? 'title-medium-B'
              : 'title-smallest-MB'
          } text-Neutral800 dark:text-Neutral800Dark`}
        >
          {likeCounter(sumVotes)} {''}
          {sumVotes > 1 ? AppConstant?.VOTES : AppConstant?.VOTE}
        </p>

        {!checkPollExpired() && !data?.vote_by_user && (
          <>
            <div className="w-[4px] h-[4px] bg-Neutral800 mx-[10px] rounded-full" />
            <p
              className={`${
                router?.pathname.startsWith(ROUTE?.POST_DETAILS)
                  ? 'title-medium-B'
                  : 'title-smallest-MB'
              } text-Neutral800 dark:text-Neutral800Dark`}
            >
              {`${AppConstant.EXPIRES_IN} ${getPollExpireTime()}`}
            </p>
          </>
        )}
      </div>
    );
  };

  // eslint-disable-next-line complexity
  const RenderPollDetails = ({ detail, onClick, salary }) => {
    const [companyShareType, setCompanyShareType] = useState('');
    const [companyShareValue, setCompanyShareValue] = useState();

    useEffect(() => {
      const salaryData = detail?.salary?.data;
      if (salaryData?.yearly_esop !== undefined) {
        setCompanyShareType('ESOPs');
        setCompanyShareValue(salaryData?.yearly_esop);
      }
      if (salaryData?.yearly_rsu !== undefined) {
        setCompanyShareType('RSUs');
        setCompanyShareValue(salaryData?.yearly_rsu);
      }
      if (salaryData?.yearly_stock !== undefined) {
        setCompanyShareType('Equity');
        setCompanyShareValue(salaryData?.yearly_stock);
      }
    }, []);

    const totalCount = [
      {
        prix: detail?.salary?.data?.fixed_salary
          ? detail?.salary?.data?.fixed_salary
          : 0
      },
      {
        prix: detail?.salary?.data?.variable_salary
          ? detail?.salary?.data?.variable_salary
          : 0
      },
      {
        prix: companyShareValue ? companyShareValue : 0
      }
    ];
    let total = totalCount.reduce((a, v) => (a = a + v.prix), 0);
    return (
      <div className="w-full flex py-[10px]">
        {salary && (
          <div className="mt-[5px]" onClick={onClick}>
            {!checkPollExpired() && !data?.vote_by_user && (
              <div className="w-[12px] h-[12px] border-[2px] border-Action rounded-full ml-[12px]" />
            )}
          </div>
        )}
        <div className="text-left w-full">
          <div onClick={onClick}>
            <div className="flex w-full break-all overflow-hidden text-ellipsis whitespace-nowrap items-center pl-[15px]">
              <p className="text-Neutral900 dark:text-Neutral900Dark title-medium-MB">
                {detail?.salary?.company !== null
                  ? detail?.salary?.company?.display_name
                  : detail?.salary?.industry_sector?.name}
              </p>
              {detail?.salary?.location?.name && (
                <>
                  <Dot className={'bg-Neutral800 '} />
                  <p className="text-Neutral800 dark:text-Neutral800Dark heading-small-M">
                    {detail?.salary?.location?.name}
                  </p>
                </>
              )}
              {detail?.salary?.is_compensation && (
                <>
                  <Dot className={'bg-Neutral800 '} />
                  <p className="text-Neutral800 dark:text-Neutral800Dark heading-small-M pr-[15px] w-full break-all overflow-hidden text-ellipsis whitespace-nowrap">
                    {'Current compensation'}
                  </p>
                </>
              )}
            </div>
            <div>
              <div className="flex w-full break-all overflow-hidden text-ellipsis whitespace-nowrap items-center pl-[15px] mt-2">
                {detail?.salary?.seniority?.name ? (
                  <p className="text-Neutral800 dark:text-Neutral800Dark heading-small-M">
                    {detail?.salary?.seniority?.name}
                  </p>
                ) : null}
                {detail?.salary?.specialization?.name ? (
                  <>
                    <Dot className={'bg-Neutral800 '} />
                    <p className="text-Neutral800 dark:text-Neutral800Dark heading-small-M">
                      {detail?.salary?.specialization?.name}
                    </p>
                  </>
                ) : null}
                {detail?.salary?.years_of_experience && (
                  <>
                    <Dot className={'bg-Neutral800 '} />
                    <p className="text-Neutral800 dark:text-Neutral800Dark heading-small-M">
                      {detail?.salary?.years_of_experience}yrs
                    </p>
                  </>
                )}
              </div>
            </div>
            <p className="text-Neutral900 dark:text-Neutral900Dark heading-medium-M mt-[4px] px-[15px] w-full break-all overflow-hidden text-ellipsis whitespace-nowrap">
              {detail?.salary?.job_title?.name}
            </p>
            <div className="flex w-full mt-[8px] ml-[15px] break-all overflow-hidden text-ellipsis whitespace-nowrap items-center">
              {detail?.salary?.company === null && (
                <>
                  <p className="text-Neutral800 dark:text-Neutral800Dark heading-small-M ">
                    {detail?.salary?.company_stage?.name}
                  </p>
                  <Dot className={'bg-Neutral800 w-[2.5px] h-[2.5px]'} />
                </>
              )}
            </div>
          </div>
          <div className="flex px-[15px] mt-2 justify-between">
            <div className="flex flex-col">
              <p className="text-Neutral900 dark:text-Neutral900Dark heading-medium-M">
                ₹{salaryDiff(total)}
              </p>
              <p className="text-Neutral800 dark:text-Neutral800Dark title-medium-MB">
                {AppConstant?.TOTAL}
              </p>
            </div>
            <div className="flex gap-6">
              <div className="flex flex-col">
                <p className="text-Neutral900 dark:text-Neutral900Dark heading-medium-M">
                  ₹
                  {salaryDiff(
                    detail?.salary?.data?.fixed_salary
                      ? detail?.salary?.data?.fixed_salary
                      : 0
                  )}
                </p>
                <p className="text-Neutral800 dark:text-Neutral800Dark title-medium-MB">
                  {AppConstant?.FIXED}
                </p>
              </div>
              <div className="flex flex-col">
                <p className="text-Neutral900 dark:text-Neutral900Dark heading-medium-M">
                  ₹
                  {salaryDiff(
                    detail?.salary?.data?.variable_salary
                      ? detail?.salary?.data?.variable_salary
                      : 0
                  )}
                </p>
                <p className="text-Neutral800 dark:text-Neutral800Dark title-medium-MB">
                  {AppConstant?.VARIABLE}
                </p>
              </div>
              <div className="flex flex-col">
                <p className="text-Neutral900 dark:text-Neutral900Dark heading-medium-M">
                  ₹
                  {salaryDiff(
                    hasValue(companyShareValue, companyShareValue, 0)
                  )}
                </p>
                <p className="text-Neutral800 dark:text-Neutral800Dark title-medium-MB">
                  Stocks
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const RenderPollTitlePer = ({ perc }) => {
    return (
      <div className="absolute w-full flex justify-between items-center">
        <div className="w-full"></div>
        <p className="text-Neutral900 dark:text-Neutral900Dark title-medium-B pr-[12px] w-[55px] text-right whitespace-nowrap">
          {perc?.toFixed(0)}%
        </p>
      </div>
    );
  };

  if (data?.poll_options?.length !== 0) {
    return (
      <div
        className={`${
          router?.pathname.startsWith(ROUTE?.POST_DETAILS)
            ? 'mt-[12px]'
            : 'mt-[10px]'
        } py-[2px] rounded-[15px] border-[0.33px] border-DividerColor dark:border-Neutral400Dark`}
      >
        <div>
          <p className="heading-medium-M text-Action m-[10px] text-left">
            {data?.poll_options?.length > 1 ? '₹ Offer comparison' : '₹ Salary'}
          </p>

          {data?.poll_options?.map((d, i) => {
            let perc = (d?.votes_count / sumVotes) * 100;
            if (isNaN(perc)) {
              perc = 0;
            }

            return (
              <div key={i} className="m-[10px]">
                <div
                  className={`
                    ${hasValue(
                      !checkPollExpired() && !data?.vote_by_user,
                      'cursor-pointer',
                      ''
                    )}
                  bg-Neutral200 dark:bg-Neutral200Dark w-full rounded-[8px] md:mr-5 mr-1 items-center relative 
                  border-[0.33px] border-transparent`}
                >
                  <RenderPollDetails
                    detail={d}
                    salary={data?.poll_options.length > 1 ? true : false}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (!checkPollExpired() && !data?.vote_by_user) {
                        _onVotePollClick({ postID: data?.id, pollData: d });
                      }
                    }}
                  />
                  {data?.poll_options.length > 1 && (
                    <div
                      className={` ${hasValue(
                        !checkPollExpired() && !data?.vote_by_user,
                        '',
                        'mb-[10px]'
                      )} mx-[10px] mt-[8px]`}
                    >
                      <div
                        className={`${hasValue(
                          !checkPollExpired() && !data?.vote_by_user,
                          'animOut',
                          'animIn !h-[36px]'
                        )}`}
                      >
                        <div
                          className="bg-Neutral200 dark:bg-Neutral200Dark w-full flex rounded-[8px] md:mr-5 mr-1 items-center relative h-[36px]
                  border-[0.33px] border-DividerColor dark:border-Neutral400Dark"
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (!checkPollExpired() && !data?.vote_by_user) {
                              _onVotePollClick({
                                postID: data?.id,
                                pollData: d
                              });
                            }
                          }}
                        >
                          <div
                            className=" bg-Progress dark:bg-ProgressDark relative rounded-[8px] h-[36px]"
                            style={{
                              width: `${hasValue(
                                !checkPollExpired() && !data?.vote_by_user,
                                0,
                                Math.round(perc, 2)
                              )}%`,
                              overflow: 'hidden',
                              display: 'inline-block',
                              transition: 'width 500ms',
                              whiteSpace: 'nowrap'
                            }}
                          />
                          <RenderPollTitlePer perc={perc} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        {data?.poll_options?.length > 1 && (
          <>
            <div className="h-[1px] bg-DividerColor dark:bg-Neutral300Dark" />
            <RenderLikeExpDate />
          </>
        )}
      </div>
    );
  }
};

export default RenderSalary;
