import React, { useContext, useEffect, useState } from 'react';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import icons from '../../assets/leftSide';
import { AppConstant } from '../../constants/AppConstant';
import { AppContext } from '../../context/AppProvider';
import { colors } from '../../themes';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import image from '../../assets/Icons';
import Image from 'next/image';

const RenderCarousel = props => {
  const context = useContext(AppContext);
  const [IsLight, setIsLight] = useState(false);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setIsLight(context?.theme === context?.themes[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.theme]);

  const arr = [
    {
      id: 1
    },
    {
      id: 2
    },
    {
      id: 3
    }
  ];

  return (
    <div className="lg:w-[638px] xl:w-[552px] w-auto mb-[27px] sm:mb-[40px]">
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={80}
        totalSlides={3}
        dragEnabled={false}
      >
        <Slider>
          {arr?.map((d, i) => {
            return (
              <Slide key={i} index={i + 1}>
                <Image
                  priority
                  src={image['carousel' + (i + 1)]}
                  alt={'logo'}
                />
              </Slide>
            );
          })}
        </Slider>
        <div className="flex z-30 -mt-[65px] sm:-mt-[80px] justify-center items-center">
          <ButtonBack
            className={` ${
              index === 0 ? 'bg-Neutral600' : 'bg-Neutral900'
            } flex rotate-90 w-[40px] h-[40px] rounded-full justify-center items-center`}
            onClick={() => setIndex(e => e - 1)}
          >
            <icons.chevronDownDark w={20} h={20} color={colors?.White} />
          </ButtonBack>
          <div className="flex z-30 title-extra-large-B mx-[24px]">
            <p className="text-Neutral900">{index + 1}</p>
            <p className="text-Neutral700 mx-[10px]">{AppConstant.OF}</p>
            <p className="text-Neutral700">{arr.length}</p>
          </div>
          <ButtonNext
            className={`${
              index + 1 === arr.length ? 'bg-Neutral600' : 'bg-Neutral900'
            } flex -rotate-90 w-[40px] h-[40px]  rounded-full justify-center items-center`}
            onClick={() => setIndex(e => e + 1)}
          >
            <icons.chevronDownDark w={20} h={20} color={colors?.White} />
          </ButtonNext>
        </div>
      </CarouselProvider>
    </div>
  );
};

export default RenderCarousel;
