import Image from 'next/image';
import React, { useEffect, useContext, useState } from 'react';
import image from '../../assets/Icons';
import { AppContext } from '../../context/AppProvider';
import {
  AppConstant,
  ASYNC_KEY,
  TOAST_MESSAGE
} from '../../constants/AppConstant';
import buildLink from '../../services/firebaseDynamicLink';
import { toast } from 'react-toastify';
import { getFromAsync } from '../../utils';

const RenderEngagement = ({ data }) => {
  const context = useContext(AppContext);
  const IsLight = context?.theme === context?.themes[0];
  const [checkLocal, setCheckLocal] = useState(null);

  useEffect(() => {
    localData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const localData = async () => {
    let tokenData = await getFromAsync(ASYNC_KEY.ACCESS_TOKEN);
    setCheckLocal(tokenData);
  };

  const _copyLinkPost = val => {
    let data = {
      postId: val?.postID,
      commentId: '',
      type: AppConstant?.POST
    };

    buildLink(data).then(res => {
      var link = res.replace(AppConstant?.POST_SHARE_MSG, '');
      navigator.clipboard.writeText(link);
      toast.info(TOAST_MESSAGE?.COPY);
    });
  };

  const _onBtn = val => {
    if (val?.btn === AppConstant?.SHARE_NOW) {
      _copyLinkPost(val);
    } else if (val?.btn === AppConstant?.CREATE_NOW) {
      context.setCreatePostModal(true);
    }
  };

  return (
    <div className="flex justify-between">
      <div className="overflow-hidden w-auto">
        <p className="text-Neutral900 dark:text-Neutral900Dark title-extra-large-B break-all text-left">
          {data?.title}
        </p>
        <button
          className="h-[35px] justify-center rounded-[100px] bg-Action flex px-[15px] mt-[16px] items-center"
          onClick={() => {
            if (checkLocal) {
              _onBtn({ btn: data?.engagement_button_title, postID: data?.id });
            } else {
              context.setLoginModal(true);
            }
          }}
        >
          <p className="title-large-B text-White">
            {data?.engagement_button_title}
          </p>
        </button>
      </div>
      <div className="ml-[12px] rounded-[12px]">
        <Image
          priority
          className="min-w-[106px] max-w-[106px] min-h-[106px] max-h-[106px] rounded-[12px]"
          src={
            data?.image !== null
              ? data?.image
              : IsLight
              ? image?.imagePlaceholder
              : image?.darkImgPlaceholder
          }
          alt="img"
          width={106}
          height={106}
        />
      </div>
    </div>
  );
};

export default RenderEngagement;
