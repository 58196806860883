import React, { useContext } from 'react';
import icons from '../../assets/leftSide';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import { hasValue, ROUTE } from '../../utils';
import { AppContext } from '../../context/AppProvider';
import { colors } from '../../themes';
import buildLink from '../../services/firebaseDynamicLink';
import { AppConstant, TOAST_MESSAGE, TYPE } from '../../constants/AppConstant';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';

const ShareMenu = ({ data, type, comment, onShare }) => {
  const router = useRouter();

  const context = useContext(AppContext);
  const IsLight = context?.theme === context?.themes[0];

  const _copyLinkPost = val => {
    if (data?.share_link) {
      navigator.clipboard.writeText(link);
      toast.info(TOAST_MESSAGE?.COPY);
    } else {
      let payload = {
        postId: val?.postID,
        commentId: comment?.id,
        type: AppConstant?.POST
      };
      buildLink(payload).then(res => {
        var link = res.replace(AppConstant?.POST_SHARE_MSG, '');
        navigator.clipboard.writeText(link);
        toast.info(TOAST_MESSAGE?.COPY);
      });
    }
  };

  const RenderMenuBtn = type => {
    return (
      <MenuButton
        aria-label="share"
        className="relative px-1 py-4"
        onClick={() => {
          if (type === TYPE?.SMALL) {
            onShare();
          }
        }}
      >
        <div className="px-[5.83px] flex items-center">
          <span className="mr-[10px]">
            {type === TYPE?.SMALL ? <icons.shareSmall /> : <icons.share />}
          </span>
          {type !== TYPE?.SMALL && (
            <p
              className={`${
                router?.pathname.startsWith(ROUTE?.POST_DETAILS)
                  ? 'title-medium-B'
                  : 'title-small-B'
              } text-Neutral800 dark:text-Neutral800Dark`}
            >
              {AppConstant?.SHARE}
            </p>
          )}
        </div>
      </MenuButton>
    );
  };

  return (
    <div className="flex justify-center items-center">
      <Menu
        transition
        menuStyle={{ borderRadius: '7px' }}
        menuButton={RenderMenuBtn(type)}
      >
        <div className="bg-white dark:bg-Neutral400Dark rounded-[4px]">
          <div className="w-[200px] menuStyle justify-center py-[5px]">
            <MenuItem className="!p-0">
              <div
                className="flex w-[200px] h-[40px] my-[5px] items-center"
                onClick={() => {
                  let link = data?.share_link;
                  if (comment) link = comment?.share_link;
                  if (link) {
                    navigator.clipboard.writeText(link);
                    toast.info(TOAST_MESSAGE?.COPY);
                  } else {
                    _copyLinkPost({
                      postID: data?.id,
                      check: data?.bookmarked
                    });
                  }
                }}
              >
                <div className="ml-[15px] mr-[10px]">
                  <icons.copyLink
                    w={19}
                    h={19}
                    color={hasValue(
                      !IsLight,
                      colors.Neutral200,
                      colors.Neutral200Dark
                    )}
                  />
                </div>
                <p
                  className="text-Action title-medium-B"
                  style={{
                    color: hasValue(
                      !IsLight,
                      colors.Neutral200,
                      colors.Neutral200Dark
                    )
                  }}
                >
                  {AppConstant?.COPYLINK}
                </p>
              </div>
            </MenuItem>
          </div>
        </div>
      </Menu>
    </div>
  );
};

export default ShareMenu;
