import React, { useContext, useEffect, useState } from 'react';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import icons from '../../assets/leftSide';
import {
  AppConstant,
  ASYNC_KEY,
  FEED_SELECT_TYPE,
  POST_TYPE
} from '../../constants/AppConstant';
import { ROUTE, getFromAsync, hasValue, setInAsyncStorage } from '../../utils';
import { AppContext } from '../../context/AppProvider';
import { colors } from '../../themes';
import * as track from '../../utils/MixpanelTrackEvent';
import { useRouter } from 'next/router';

const SubHeader = props => {
  const context = useContext(AppContext);
  const router = useRouter();
  const [IsLight, setIsLight] = useState(false);

  useEffect(() => {
    setIsLight(context?.theme === context?.themes[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.theme]);

  const [selectPostType, setSelectPostType] = useState('');

  const arr = props?.overrideFilterOptions?.length
    ? props?.overrideFilterOptions
    : [
        {
          id: 1,
          name: AppConstant?.POPULAR,
          type: FEED_SELECT_TYPE.POPULAR,
          icon: icons?.typePopular,
          light: colors.Neutral200,
          dark: colors.Neutral200Dark
        },
        {
          id: 2,
          name: AppConstant?.RECENT,
          type: FEED_SELECT_TYPE.RECENT,
          icon: icons?.typeRecent,
          light: colors.Neutral200,
          dark: colors.Neutral200Dark
        },
        {
          id: 3,
          name: AppConstant?.WEEK,
          type: FEED_SELECT_TYPE.TOP_OF_THE_WEEK,
          icon: icons?.typeWeek,
          light: colors.Neutral200,
          dark: colors.Neutral200Dark
        },
        {
          id: 4,
          name: AppConstant?.MONTH,
          type: FEED_SELECT_TYPE.TOP_OF_THE_MONTH,
          icon: icons?.typeMonth,
          light: colors.Neutral200,
          dark: colors.Neutral200Dark
        }
      ];

  useEffect(() => {
    const feed = getFromAsync(ASYNC_KEY.FEED_TYPE);
    setSelectPostType(
      feed !== null && feed !== undefined ? feed : FEED_SELECT_TYPE.POPULAR
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _onPostType = val => {
    setSelectPostType(val);
    track.trackHomeSorted(val);
    setInAsyncStorage(ASYNC_KEY?.FEED_TYPE, val);
    props?.feedType(val);
  };

  const _onCreatePost = async val => {
    const tokenData = await getFromAsync(ASYNC_KEY.ACCESS_TOKEN);
    if (tokenData) {
      context.setCreatePostModal(true);
      context.setCreatePostType(val);
    } else {
      context.setLoginModal(true);
    }
  };

  // eslint-disable-next-line complexity
  const MenuButtonComp = () => {
    let text;
    if (selectPostType === FEED_SELECT_TYPE.POPULAR) {
      text = AppConstant?.POPULAR;
    } else if (selectPostType === FEED_SELECT_TYPE.RECENT) {
      text = AppConstant?.RECENT;
    } else if (selectPostType === FEED_SELECT_TYPE.TOP_OF_THE_MONTH) {
      text = AppConstant?.MONTH;
    } else if (selectPostType === FEED_SELECT_TYPE.TOP_OF_THE_WEEK) {
      text = AppConstant?.WEEK;
    }
    return (
      <MenuButton
        className="relative"
        onClick={() => {
          track.trackHomeSortTap();
        }}
      >
        <div className="flex items-center mr-[8px]">
          <p className="text-Neutral800 title-smallest-MB mr-[4px]">
            {AppConstant?.SORT}
          </p>
          <p className="text-Action title-smallest-MB whitespace-nowrap mr-[4px]">
            {`${text} ${AppConstant?.Posts}`}
          </p>
          <icons.chevronDownDark color={colors?.Action} />
        </div>
      </MenuButton>
    );
  };

  return (
    <div className="">
      <div className="h-[54px] flex items-center px-[10px] bg-White dark:bg-DarkBg border-[0.33px] rounded-[12px] border-DividerColor dark:border-Neutral400Dark">
        <div
          className="flex h-[37px] w-full items-center px-[10px] bg-Neutral200 dark:bg-Neutral200Dark rounded-[6px] cursor-pointer"
          onClick={() => _onCreatePost(POST_TYPE?.TEXT)}
        >
          <p className="heading-medium-M text-Neutral500 dark:text-Neutral500Dark">
            {AppConstant?.CREATE_POST}
          </p>
        </div>
        <div
          className="w-[47.2px] flex justify-center cursor-pointer"
          onClick={() => _onCreatePost(POST_TYPE?.IMAGE)}
        >
          <icons.dImage
            color={hasValue(IsLight, colors.Neutral900, colors.Neutral900Dark)}
          />
        </div>
        <div
          className="w-[47.2px] flex justify-center cursor-pointer"
          onClick={() => _onCreatePost(POST_TYPE?.POLL)}
        >
          <icons.dPoll
            color={hasValue(IsLight, colors.Neutral900, colors.Neutral900Dark)}
          />
        </div>
        <div
          className="w-[47.2px] flex justify-center cursor-pointer"
          onClick={() => _onCreatePost(POST_TYPE?.LINK)}
        >
          <icons.dLink
            color={hasValue(IsLight, colors.Neutral900, colors.Neutral900Dark)}
          />
        </div>
      </div>

      {context?.token && !router.pathname.includes(ROUTE.INTRA) ? (
        <div className="h-[24px] w-full flex items-center my-[10px] relative">
          <div className="flex w-full justify-center items-center">
            <Menu
              transition
              menuStyle={{ borderRadius: '7px' }}
              menuButton={MenuButtonComp()}
            >
              <div className="bg-white dark:bg-Neutral400Dark rounded-[4px]">
                <div className="w-[200px] menuStyle justify-center py-[5px]">
                  {arr?.map((d, i) => {
                    let Icon = d?.icon;
                    let sel = selectPostType === d?.type;
                    return (
                      <MenuItem className="!p-0" key={i}>
                        <div
                          className="flex w-[200px] h-[40px] my-[3px] items-center"
                          onClick={() => {
                            _onPostType(d?.type);
                          }}
                        >
                          <div className="ml-[15px] mr-[10px]">
                            <Icon
                              color={hasValue(
                                !IsLight,
                                sel ? colors.Action : colors.Neutral200,
                                sel ? colors.Action : colors.Neutral200Dark
                              )}
                            />
                          </div>
                          <p
                            className={`${
                              sel
                                ? 'text-Action'
                                : 'text-Neutral200Dark dark:text-Neutral200'
                            } title-medium-B`}
                          >
                            {d?.name}
                          </p>
                        </div>
                      </MenuItem>
                    );
                  })}
                </div>
              </div>
            </Menu>
            <div className="h-[0.33px] w-full bg-DividerColor dark:bg-Neutral400Dark" />
          </div>
        </div>
      ) : (
        <div className="mt-[10px]" />
      )}
    </div>
  );
};

export default SubHeader;
