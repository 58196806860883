import API_CONSTANT from '../../constants/ApiConstant';
import { ASYNC_KEY } from '../../constants/AppConstant';
import { get } from '../../services/ApiServices';
import { setInAsyncStorage } from '../../utils';
import { setUserData } from './Reducer';

const getUserAPI = (dispatch, query) => {
  return new Promise((resolve, reject) => {
    get(`${API_CONSTANT.USER}${query}`)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getUserProfileAPI = dispatch => {
  return new Promise((resolve, reject) => {
    get(`${API_CONSTANT.USER}`)
      .then(res => {
        dispatch(setUserData(res.data.data));
        setInAsyncStorage(ASYNC_KEY.USER, res.data.data);
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getUserPostsAPI = (dispatch, query) => {
  return new Promise((resolve, reject) => {
    get(`${API_CONSTANT.USER_POSTS}${query}`)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getUserCommentsAPI = (dispatch, query) => {
  return new Promise((resolve, reject) => {
    get(`${API_CONSTANT.USER_PROFILE_COMMENT}${query}`)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const getUserBookmarksAPI = (dispatch, query) => {
  return new Promise((resolve, reject) => {
    get(`${API_CONSTANT.USER_PROFILE_BOOKMARK}${query}`)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};
export {
  getUserAPI,
  getUserProfileAPI,
  getUserPostsAPI,
  getUserCommentsAPI,
  getUserBookmarksAPI
};
